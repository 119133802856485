import { h } from "preact";
import { useState } from "preact/hooks";
import StepActions from "../../step-actions";

import { getErrorMessage } from "../../../utils";
import { ACCOUNT_TYPE } from "../../../constants";

const Account = ({
  next,
  previous,
  accountName: defaultAccountName,
  hyphaTokenomicsPaper,
  accountType,
  setAccount,
  setAccountType,
  createAccountLink,
  createAccountLinkText,
  widgetText2,
  widgetText1,
  getAccountDetails,
}) => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [accountName, setAccountName] = useState(defaultAccountName);

  const handleInputChange = (event, props) => {
    const accountName = event.target.value.toLowerCase();
    setError(false);
    setAccountName(accountName);
  };

  const getAccountAndNavigate = async () => {
    setLoading(true);
    try {
      await getAccountDetails(accountName);
      await next();
    } catch (e) {
      setLoading(false);
      setError("account-not-found");
    }
  };

  const toggleAccountType = () => {
    setAccountType(
      accountType === ACCOUNT_TYPE.SEEDS
        ? ACCOUNT_TYPE.HYPHA_TELOS
        : ACCOUNT_TYPE.SEEDS
    );
  };

  return (
    <div id="step-1">
      <div class="generic-card glow-back has-link account-vaidation">
        <div class="wrappo">
          <div class="step-nav">
            <div class="stp active">
              <p>1</p>
            </div>
            <div class="stp"></div>
            <div class="stp"></div>
            <div class="stp"></div>
          </div>

          <div class="top">
            <h2 class="titles white">Hypha Account</h2>
            <p class="subtitle">{widgetText1}</p>
            <p className="text-small white">
              {widgetText2}
              <a href={createAccountLink} target="_blank">
                {createAccountLinkText}
              </a>
            </p>
          </div>
          <div class="bottom">
            <input
              id="account-name"
              value={accountName}
              placeholder={`Enter your ${
                accountType === ACCOUNT_TYPE.SEEDS ? "SEEDS" : "Hypha"
              } account`}
              class={error && "error"}
              onChange={handleInputChange}
            />
            {error && <div class="error-message">{getErrorMessage(error)}</div>}

            <StepActions
              next={next && getAccountAndNavigate}
              previous={previous}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <p class="explainer">
        To learn more about our tokenomics{" "}
        <a href={hyphaTokenomicsPaper} target="_blank">
          click here
        </a>
      </p>
    </div>
  );
};

export default Account;
