import { h } from "preact";
import { useState } from "preact/hooks";
import StepActions from "../../step-actions";

import {
  getErrorMessage,
  formatNumber,
  stripNonNumericValues,
} from "../../../utils";
import {
  BTC_DECIMAL_PLACES,
  EOS_DECIMAL_PLACES,
  TOKENS,
} from "../../../constants";

const ChooseAmount = ({
  next,
  previous,
  token,
  hyphaRemainingThisRound,
  hyphaRemaining,
  setHyphaAmount,
  amount,
  account,
  convertHyphaToCrypto,
  convertHyphaToUsd,
}) => {
  const [error, setError] = useState();
  const availableHyphaPretty = formatNumber(hyphaRemaining);
  const availableHyphaThisPretty = formatNumber(hyphaRemainingThisRound);
  const availableUsdPretty = formatNumber(convertHyphaToUsd(hyphaRemaining));

  const handleInputChange = (e) => {
    let { value } = e.target;

    const amount = stripNonNumericValues(value);
    const maxAmount = Number(hyphaRemaining);

    if (amount > maxAmount) {
      setError("high_amount");
      setHyphaAmount(maxAmount);
    } else {
      setError();
      setHyphaAmount(amount);
    }
  };
  return (
    <div id="step-3">
      <div class="generic-card glow-back has-link amount-to-choose">
        <div class="wrappo">
          <div class="step-nav">
            <div class="stp done"></div>
            <div class="stp done"></div>
            <div class="stp active">
              <p>3</p>
            </div>
            <div class="stp"></div>
          </div>
          {account.profilePhoto && (
            <div id="account-image">
              <img src={account.profilePhoto} />
            </div>
          )}

          <div class="top">
            <div class="cont-count">
              <img src="img/tokens/hypha-icon.png" />
              <p id="hypha-available" class="white big-amount">
                {availableHyphaPretty}
              </p>
            </div>
            <p class="text-small">
              Remaining{" "}
              <span id="token-selected-type" class="bold white">
                Hypha Tokens
              </span>{" "}
              available
            </p>
          </div>

          <div class="bottom">
            <p class="text-big white">Choose Amount</p>
            <p class="text-small">
              Type in the amount of Hypha you would like to buy. The value of{" "}
              {token} and USD will dynamically be updated.
            </p>
            <div class="input-amount">
              <div class="input-wrap">
                <input
                  id="hypha-amount-to-buy"
                  class={error && "warning"}
                  value={amount}
                  placeholder={formatNumber(amount, 2)}
                  onInput={handleInputChange}
                ></input>

                {error && (
                  <div class="warning-message">{getErrorMessage(error)}</div>
                )}
              </div>
              <div id="converter">
                <div class="conv-cript">
                  <b>
                    <span id="selected-method">{token}</span>
                  </b>{" "}
                  <span id="conversion-cripto">
                    {" "}
                    {formatNumber(
                      convertHyphaToCrypto(amount),
                      token === TOKENS.EOS
                        ? EOS_DECIMAL_PLACES
                        : BTC_DECIMAL_PLACES
                    )}
                  </span>
                </div>

                <div class="conv-usd">
                  <b>USD</b>{" "}
                  <span id="conversion-usd">
                    {" "}
                    {formatNumber(convertHyphaToUsd(amount))}
                  </span>
                </div>
              </div>
            </div>

            <StepActions next={next} previous={previous} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseAmount;
