import { h, Fragment } from "preact";
import CopyToClipboard from "react-copy-to-clipboard";
import { DEFAULT_HYPHA_EOS_ADDRESS } from "../../../../constants";

const EosBillCard = ({
  amount,
  usdAmount,
  accountName,
  hyphaAddress = DEFAULT_HYPHA_EOS_ADDRESS,
}) => {
  return (
    <>
      <div id="eos-bill-card" class="eos-bill-card">
        <div class="address-title">
          <div>
            EOS Address:
            <span id="hypha-eos-address">{hyphaAddress}</span>
          </div>
          <CopyToClipboard text={hyphaAddress}>
            <div class="copy"></div>
          </CopyToClipboard>
        </div>

        <div class="memo-space">
          <div>
            with the memo:
            <span id="hypha-eos-memo">{accountName}</span>
          </div>
          <CopyToClipboard class="copy" text={accountName}>
            <div class="copy"></div>
          </CopyToClipboard>
        </div>

        <div class="eos-amount-space">
          <div>
            EOS amount:
            <span id="total-eos-to-send">~{amount} </span>
          </div>
          <CopyToClipboard text={amount}>
            <div class="copy"></div>
          </CopyToClipboard>
        </div>
        <div class="eos-amount-space">
          <div>
            USD amount:
            <span id="total-eos-to-send">{usdAmount} </span>
          </div>
          <CopyToClipboard text={usdAmount}>
            <div class="copy"></div>
          </CopyToClipboard>
        </div>
      </div>
      <div class="eos-bottom">
        <p class="text-small">
          Tokens will be transferred to your account in a few minutes.
        </p>
      </div>
    </>
  );
};

export default EosBillCard;
