import { STEPS, ACCOUNT_TYPE } from "../constants";
import SimpleCrypto from "simple-crypto-js";

const formatNumber = (number, decimalPoints = 2) =>
  addCommas(Number(number).toFixed(decimalPoints));

const addCommas = (n) => {
  var rx = /(\d+)(\d{3})/;
  return String(n).replace(/^\d+/, function (w) {
    while (rx.test(w)) {
      w = w.replace(rx, "$1,$2");
    }
    return w;
  });
};

const stripNonNumericValues = (val) => {
  let value = val;
  if (isNaN(value)) {
    value = value.replace(/[^0-9\.]/g, "");
    if (value.split(".").length > 2) value = value.replace(/\.+$/, "");
  }
  return value;
};

const getErrorMessage = (e) => {
  const ERROR_MAP = {
    high_amount: `If you want to buy more then currently available tokens, please contact us`,
    "account-not-found": "Could not find account",
  };

  return ERROR_MAP[e] || e;
};

const getCurrentStep = ({ accountName, amount, selectedToken }) => {
  if (!accountName) return STEPS.ACCOUNT;
  if (!selectedToken) return STEPS.WELCOME;
  if (!amount) return STEPS.AMOUNT;
  return STEPS.FINALISE;
};

const getWidgetParamsFromURL = async ({ decryptKey }) => {
  const simpleCrypto = new SimpleCrypto(decryptKey);
  function getQuerystring() {
    let output = {};
    if (window.location.search) {
      var queryParams = window.location.search.substring(1);
      var listQueries = queryParams.split("&");
      for (var query in listQueries) {
        if (listQueries.hasOwnProperty(query)) {
          var queryPair = listQueries[query].split("=");
          output[queryPair[0]] = decodeURIComponent(queryPair[1] || "");
        }
      }
    }
    return output;
  }
  const queryParams = getQuerystring();
  const { daoActivation } = queryParams;

  const params = daoActivation
    ? simpleCrypto.decrypt(daoActivation)
    : queryParams;

  const { account, amount, token, accountType, disableGoBack } = params;

  return {
    account,
    amount,
    token,
    accountType,
    disableGoBack,
    isDaoActivation: !!daoActivation,
  };
};

export {
  getCurrentStep,
  getErrorMessage,
  getWidgetParamsFromURL,
  formatNumber,
  addCommas,
  stripNonNumericValues,
};
