import { h } from "preact";
import StepActions from "../../step-actions";
import { TOKENS, HYPHA_TITLE } from "../../../constants";
import { formatNumber } from "../../../utils";

const HyphaTokenSalesRoundInfo = ({ usdPerHypha, roundNo, totalRounds }) => (
  <div id="token-sale-info">
    <p class="welcom">Welcome to the</p>
    <p class="hts">
      Hypha Token Sale{" "}
      <span class="htsn">
        <span id="sale-round">{roundNo}</span> /{totalRounds}
      </span>
    </p>

    <p class="hypha-price">
      Hypha token price for this round is:{" "}
      <span id="hypha-value-usd">{usdPerHypha}</span>$
    </p>
    <p class="p-small">
      Each round is 100,000 tokens, with $1.00 per token in the first round.{" "}
      <br />
      Price will increase by $0.03 each round.
    </p>
  </div>
);

const DaoActivationAmount = ({ usdPerHypha, amount }) => {
  const hyphaPretty = formatNumber(amount);
  const usdPretty = formatNumber(usdPerHypha * amount);
  return (
    <div id="amount-info">
      <p>Hypha Tokens to buy:</p>
      <p class="hypha-amount-purchase">
        <img class="hypha-curr" src="img/round-logo.svg" />
        <span id="hypha-amount-to-buy">{hyphaPretty}</span>
      </p>
      <p class="conversion">
        <span id="usd-amount-to-buy">{usdPretty}</span>
        <span class="doll">
          <strong>USD</strong>
        </span>
      </p>
    </div>
  );
};

const LaunchTokens = ({ usdPerHypha }) => {
  return (
    <p class="subtitle">
      1 Hypha token = <span id="hypha-value-usd">${usdPerHypha}</span> USD
    </p>
  );
};

const Welcome = ({
  account,
  isDaoActivation,
  roundNo,
  totalRounds,
  token,
  amount,
  usdPerHypha,
  setToken,
  next,
  previous,
}) => {
  return (
    <div id="step-2">
      <div class="generic-card glow-back has-link token-options-to-buy">
        <div class="wrappo">
          {!isDaoActivation && (
            <div class="step-nav">
              <div class="stp done"></div>
              <div class="stp active">
                <p>2</p>
              </div>
              <div class="stp"></div>
              <div class="stp"></div>
            </div>
          )}
          {account.profilePhoto && (
            <div id="account-image">
              <img src={account.profilePhoto} />
            </div>
          )}

          <div class="top">
            <p class="text-small white">
              Hi{" "}
              <span id="account-name">
                {account.nickname || account.account}
              </span>
              ! You can now purchase:
            </p>
            <h2 class="titles white">{HYPHA_TITLE}</h2>
            {isDaoActivation ? (
              <DaoActivationAmount amount={amount} usdPerHypha={usdPerHypha} />
            ) : (
              <LaunchTokens usdPerHypha={usdPerHypha} />
            )}
          </div>

          <div class="bottom">
            <p class="text-big white">Payment Method</p>
            <p class="text-small">
              Select how you would like to buy Hypha token
            </p>
            <div id="payment-options">
              <div
                class={
                  token === TOKENS.EOS
                    ? "token-select selected"
                    : "token-select"
                }
                id="choose-EOS"
                onClick={() => setToken(TOKENS.EOS)}
              >
                <img src="img/tokens/eos.png" />
                <div>
                  <p class="white bold">EOS</p>
                  <p class="grey text-small">EOS</p>
                </div>
              </div>
              <div
                class={
                  token === TOKENS.BTC
                    ? "token-select selected"
                    : "token-select"
                }
                id="choose-BTC"
                onClick={() => setToken(TOKENS.BTC)}
              >
                <img src="img/tokens/btc.png" />
                <div>
                  <p class="white bold">Bitcoin</p>
                  <p class="grey text-small">BTC</p>
                </div>
              </div>
            </div>

            <StepActions next={next} previous={previous} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
