const isLaunchToken = false;
export const TOKENS = {
  EOS: "EOS",
  BTC: "BTC",
};
export const DEFAULT_HYPHA_EOS_ADDRESS = isLaunchToken
  ? "hyphasale111"
  : "thehyphasale";
export const DEFAULT_DISCORD_LINK = "";
export const EOS_DECIMAL_PLACES = 4;
export const BTC_DECIMAL_PLACES = 8;
export const QRCODE_COLOR = "#111E3B";
export const HYPHA_TITLE = isLaunchToken
  ? "Hypha Launch Stakeholders Sale"
  : "Purchase Hypha Tokens";
export const STEPS = {
  ACCOUNT: "account",
  WELCOME: "welcome",
  AMOUNT: "amount",
  FINALISE: "finalise",
  DAO_ACTIVATION: "daoActivation",
};

export const ACCOUNT_TYPE = {
  SEEDS: "seeds_telos",
  HYPHA_TELOS: "hypha_telos",
};

export const RPC_ENDPOINT = "https://mainnet.telos.net";
export const API_ENDPOINT = isLaunchToken
  ? "http://launchsale-api.hypha.earth"
  : "http://api-tokensale.hypha.earth";
export const PPP_ENV = "prod";
export const HYPHA_SALE_CODE = isLaunchToken ? "launch.hypha" : "sale.hypha";
export const HYPHA_SALE_SCOPE = isLaunchToken ? "launch.hypha" : "sale.hypha";

export const TEST_ACCOUNTS = {
  SEEDS: "aleksandardj",
  TELOS: "testaccount2",
  HYPHA: "",
};
