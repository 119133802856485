import { h, Fragment } from "preact";
import Spinner from "../../../spinner";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import { useEffect, useState } from "preact/hooks";

import { QRCODE_COLOR } from "../../../../constants";

const BtcBillCard = ({ accountName, amount, usdAmount, getBtcAddress }) => {
  const [btcAddress, setBtcAddress] = useState();

  useEffect(async () => {
    const userBtcAddress = await getBtcAddress(accountName);
    setBtcAddress(userBtcAddress);
  }, [accountName, getBtcAddress]);

  return (
    <>
      <div id="btc-bill-card" class="btc-bill-card">
        <div class="btc-address-title">
          <div>
            BTC Address:{" "}
            <CopyToClipboard text={btcAddress}>
              <div class="copy"></div>
            </CopyToClipboard>
          </div>
          <span id="hypha-btc-address">{btcAddress}</span>
        </div>

        <div class="btc-amount-space">
          <div>
            BTC amount:
            <span id="total-btc-to-send">~{amount}</span>
          </div>
          <CopyToClipboard text={amount}>
            <div class="copy"></div>
          </CopyToClipboard>
        </div>

        <div class="btc-amount-space">
          <div>
            USD amount:
            <span id="total-btc-to-send">{usdAmount}</span>
          </div>
          <CopyToClipboard text={usdAmount}>
            <div class="copy"></div>
          </CopyToClipboard>
        </div>
      </div>

      <div class="btc-bottom">
        <div>
          <p class="white bold">
            Or scan this QR code to execute the transaction
          </p>
          <p class="text-small">
            <i>
              Hypha will be transferred as soon as there is more than 1
              confirmation on your Bitcoin transaction
            </i>
          </p>
        </div>
        <div id="btc-qr-code">
          {btcAddress ? (
            <QRCode
              value={btcAddress}
              bgColor={QRCODE_COLOR}
              fgColor="white"
              size={160}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
};

export default BtcBillCard;
