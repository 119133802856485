import { Component, h } from "preact";
import HyphaTokenSale from "../utils/hypha-token-sales-util/main";

import Account from "./pages/account";
import Welcome from "./pages/welcome";
import ChooseAmount from "./pages/choose-amount";
import FinalisePurchase from "./pages/finalise-purchase";
import LoadingPage from "./loading-page";

import { getCurrentStep, getWidgetParamsFromURL } from "../utils";
import {
  TOKENS,
  DEFAULT_DISCORD_LINK,
  STEPS,
  ACCOUNT_TYPE,
} from "../constants";

class App extends Component {
  constructor(props) {
    super(props);

    const { defaultAccount, defaultToken, defaultAmount, enableQueryParams } =
      props;

    this.state = {
      loading: true,
      error: false,
      currentStep:
        !enableQueryParams &&
        getCurrentStep({
          accountName: defaultAccount,
          amount: defaultAmount,
          selectedToken: defaultToken,
        }),
      currentRound: null,
      usdPerHypha: null,
      hyphaRemainingThisRound: null,
      initialLoading: true,
      discordLink: props.discordLink || DEFAULT_DISCORD_LINK,
    };
  }

  /**
   *
   * @return {void}@memberof App
   */
  async componentDidMount() {
    const {
      rpcUrl,
      apiUrl,
      defaultAccount,
      defaultToken,
      defaultAmount,
      defaultAccountType,
      code,
      scope,
      enableQueryParams,
      decryptKey,
      isTestnet,
    } = this.props;

    console.log("SCODE AND SCOPE", code, scope);

    try {
      const queryParams =
        enableQueryParams && (await getWidgetParamsFromURL({ decryptKey }));

      const accountName = !!queryParams?.account
        ? queryParams?.account
        : defaultAccount;
      const selectedToken =
        queryParams?.token?.toUpperCase() || defaultToken || TOKENS.BTC;
      const amount = queryParams?.amount || defaultAmount || 1000;
      const disableGoBack = queryParams?.disableGoBack;
      const accountType =
        queryParams?.accountType ||
        defaultAccountType ||
        ACCOUNT_TYPE.HYPHA_TELOS;
      const isDaoActivation = queryParams?.isDaoActivation;
      const enableGoBack = !disableGoBack;

      const currentStep = getCurrentStep({
        accountName: queryParams?.account,
        selectedToken: queryParams?.token,
        amount: queryParams?.amount,
        isDaoActivation,
      });

      const hyphaTokenSale = new HyphaTokenSale(
        rpcUrl,
        apiUrl,
        code,
        scope,
        accountType,
        isTestnet
      );
      this.hyphaTokenSale = hyphaTokenSale;

      const { usdPerHypha, hyphaRemainingThisRound, currentRound } =
        await this.hyphaTokenSale.init();

      const priceMap =
        await this.hyphaTokenSale.hyphaContract.getPricePerRound();
      const totalRounds = Object.keys(priceMap).length;
      const hyphaRemaining =
        await this.hyphaTokenSale.hyphaContract.getHyphaBalance(code);
      const btcPerHypha = await this.hyphaTokenSale.convertHyphaToBTC(1);
      const eosPerHypha = await this.hyphaTokenSale.convertHyphaToEOS(1);

      const account =
        accountName &&
        (await this.hyphaTokenSale.getAccountDetails(accountName));

      const state = Object.assign({}, this.state, {
        account,
        accountName,
        amount,
        currentStep,
        selectedToken,
        eosPerHypha,
        btcPerHypha,
        usdPerHypha,
        priceMap,
        hyphaRemaining,
        hyphaRemainingThisRound,
        roundNo: currentRound,
        totalRounds,
        loading: false,
        enableGoBack,
        accountType,
        isDaoActivation,
      });

      this.setState(state);
    } catch (e) {
      this.setState({
        ...this.state,
        loadingError: e.message,
        loading: false,
      });
    }
  }

  setCurrentStep = (step) => {
    this.setState({ ...this.state, currentStep: step });
  };

  chooseToken = async (token) =>
    this.setState({ ...this.state, selectedToken: token });

  setAccount = async (account) => {
    if (!!account) {
      await this.setState({
        ...this.state,
        account,
        accountName: account.accountName,
      });
    }
  };

  setAccountType = (accountType) => {
    this.hyphaTokenSale.setAccountType(accountType);
    this.setState({ ...this.state, accountType });
    this.hyphaContract = this.hyphaTokenSale.hyphaContract;
  };

  setHyphaAmount = (amount) => this.setState({ ...this.state, amount });

  convertHyphaToEos = (val) => {
    return val * this.state.eosPerHypha;
  };
  convertHyphaToBtc = (val) => val * this.state.btcPerHypha;
  convertHyphaToUsd = (val) => {
    const { priceMap, hyphaRemainingThisRound, roundNo } = this.state;

    const cost = this.hyphaTokenSale.hyphaContract.calculateCost({
      amount: val,
      currentRound: roundNo,
      hyphaRemainingThisRound,
      priceMap,
    });

    return Number(cost).toFixed(2);
  };

  getAccountDetails = async (accountName) => {
    const account = await this.hyphaTokenSale.accountContract.getProfile(
      accountName
    );
    await this.setAccount(account);
  };

  getBtcAddress = async (accountName) => {
    return await this.hyphaTokenSale.getBitcoinAddress(accountName);
  };

  render(_props, state) {
    const {
      loadingError,
      currentStep,
      roundNo,
      selectedToken,
      usdPerHypha,
      hyphaRemaining,
      hyphaRemainingThisRound,
      accountName,
      account,
      amount,
      totalRounds,
      discordLink,
      enableGoBack,
      accountType,
      isDaoActivation,
    } = state;

    return (
      <section className="multistep">
        <div className="area">
          {!currentStep && <LoadingPage error={loadingError} />}
          {currentStep === "account" && (
            <Account
              accountName={accountName}
              discordLink={discordLink}
              setAccountType={this.setAccountType}
              accountType={accountType}
              createAccountLink={this.props.createAccountLink}
              hyphaTokenomicsPaper={
                this.props.hyphaTokenomicsPaper ||
                "https://hypha.earth/tokenomics/"
              }
              createAccountLinkText={
                this.props.createAccountLinkText || "click here "
              }
              widgetText2={
                this.props.widgetText2 || "if you don't have an account yet, "
              }
              widgetText1={
                this.props.widgetText1 ||
                "Validate your Hypha Account to proceed"
              }
              getAccountDetails={this.getAccountDetails}
              next={
                state.loading ? null : () => this.setCurrentStep(STEPS.WELCOME)
              }
            />
          )}
          {(currentStep === "welcome" ||
            currentStep === STEPS.DAO_ACTIVATION) && (
            <Welcome
              isDaoActivation={isDaoActivation}
              setToken={this.chooseToken}
              token={selectedToken}
              account={account}
              roundNo={roundNo}
              totalRounds={totalRounds}
              usdPerHypha={usdPerHypha}
              amount={amount}
              previous={
                enableGoBack ? () => !this.setCurrentStep(STEPS.ACCOUNT) : null
              }
              next={() =>
                this.setCurrentStep(
                  isDaoActivation ? STEPS.FINALISE : STEPS.AMOUNT
                )
              }
            />
          )}
          {currentStep === STEPS.AMOUNT && (
            <ChooseAmount
              accountName={accountName}
              token={selectedToken}
              hyphaRemaining={hyphaRemaining}
              hyphaRemainingThisRound={hyphaRemainingThisRound}
              setHyphaAmount={this.setHyphaAmount}
              amount={amount}
              account={account}
              convertHyphaToUsd={this.convertHyphaToUsd}
              convertHyphaToCrypto={
                selectedToken === TOKENS.EOS
                  ? this.convertHyphaToEos
                  : this.convertHyphaToBtc
              }
              previous={
                enableGoBack ? () => this.setCurrentStep("welcome") : null
              }
              next={() => this.setCurrentStep("finalise")}
            />
          )}
          {currentStep === "finalise" && (
            <FinalisePurchase
              amount={amount}
              account={account}
              isDaoActivation={isDaoActivation}
              getBtcAddress={this.getBtcAddress}
              convertHyphaToUsd={this.convertHyphaToUsd}
              convertHyphaToCrypto={
                selectedToken === TOKENS.EOS
                  ? this.hyphaTokenSale.convertHyphaToEOS
                  : this.hyphaTokenSale.convertHyphaToBTC
              }
              token={selectedToken}
              accountName={accountName}
              previous={
                enableGoBack ? () => this.setCurrentStep("amount") : null
              }
            />
          )}
        </div>
      </section>
    );
  }
}

export default App;
