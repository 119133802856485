import { h, Fragment } from "preact";
import {
  BTC_DECIMAL_PLACES,
  EOS_DECIMAL_PLACES,
  TOKENS,
  HYPHA_TITLE,
} from "../../../constants";
import EosBillCard from "./eos";
import BtcBillCard from "./btc";
import StepActions from "../../step-actions";
import useAsync from "../../../hooks/useAsync";
import Spinner from "../../spinner";
import { formatNumber } from "../../../utils";

const FinalisePurchase = ({
  convertHyphaToCrypto,
  getBtcAddress,
  convertHyphaToUsd,
  amount,
  token,
  accountName,
  account,
  next,
  previous,
  isDaoActivation,
}) => {
  const [cryptoAmount, error, isLoading] = useAsync(
    () =>
      convertHyphaToCrypto(amount).then(
        (calculatedCryptoAmount) => calculatedCryptoAmount
      ),
    [amount]
  );

  return (
    <div id="step-4">
      <div class="generic-card glow-back has-link final-step">
        <div class="wrappo">
          {!isDaoActivation && (
            <div class="step-nav">
              <div class="stp done"></div>
              <div class="stp done"></div>
              <div class="stp done"></div>
              <div class="stp active">
                <p>4</p>
              </div>
            </div>
          )}
          {account.profilePhoto && (
            <div id="account-image">
              <img src={account.profilePhoto} />
            </div>
          )}

          <div class="top">
            <h2 class="titles white">Finalise Purchase</h2>
            <p class="text-small light-blu">
              To buy{" "}
              <span id="total-hypha-to-buy">{formatNumber(amount)} </span>
              <span id="token-selected-type">{HYPHA_TITLE}</span>
            </p>
            <p class="text-small light-blu">
              transfer the exact amount of{" "}
              <span id="selected-method"> {token}</span> to:
            </p>
          </div>

          <div class="bottom">
            {token === TOKENS.EOS && (
              <EosBillCard
                accountName={accountName}
                usdAmount={isLoading ? <Spinner /> : convertHyphaToUsd(amount)}
                amount={
                  isLoading ? (
                    <Spinner />
                  ) : (
                    formatNumber(cryptoAmount, EOS_DECIMAL_PLACES)
                  )
                }
              />
            )}
            {token === TOKENS.BTC && (
              <BtcBillCard
                getBtcAddress={getBtcAddress}
                accountName={accountName}
                usdAmount={isLoading ? <Spinner /> : convertHyphaToUsd(amount)}
                amount={
                  isLoading ? (
                    <Spinner />
                  ) : (
                    formatNumber(cryptoAmount, BTC_DECIMAL_PLACES)
                  )
                }
              />
            )}

            <StepActions next={next} previous={previous} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalisePurchase;
