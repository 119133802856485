class Profile {
  constructor(profileDetails) {
    Object.assign(this, profileDetails);
    this.accountName = profileDetails.accountName;
    this.nickname = profileDetails.nickname || profileDetails.accountName;
    this.profilePhoto = profileDetails.profilePhoto;
  }
}

export default Profile;
