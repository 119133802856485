import TokenSaleAPI from "./api/tokenSale";
import HyphaSaleContract from "./contract/hypha-sale";
import SeedsAccountContract from "./contract/seeds-account";
import HyphaAccountContract from "./contract/hypha-account";
import { initAPI, initRPC } from "./utils/index";
import { EOS_DECIMAL_PLACES, BTC_DECIMAL_PLACES } from "../../constants/index";

const ACCOUNT_TYPES = {
  SEEDS_TELOS: "seeds_telos",
  HYPHA_TELOS: "hypha_telos",
};

const ACCOUNT_CONTRACT_CLASSES = {
  [ACCOUNT_TYPES.SEEDS_TELOS]: SeedsAccountContract,
  [ACCOUNT_TYPES.HYPHA_TELOS]: HyphaAccountContract,
};

const getAccountContractClass = (accountType) => {
  return ACCOUNT_CONTRACT_CLASSES[accountType];
};

class HyphaTokenSale {
  constructor(rpcUrl, apiUrl, code, scope, accountType, isTestNet) {
    this.rpcEndpoint = rpcUrl;
    this.apiEndpoint = apiUrl;

    this.code = code;
    this.scope = scope;
    this.convertHyphaToEOS = this.convertHyphaToEOS.bind(this);
    this.convertHyphaToBTC = this.convertHyphaToBTC.bind(this);
    this.accountType = accountType || ACCOUNT_TYPES.HYPHA_TELOS;
    this.setAccountType = this.setAccountType.bind(this);
    this.init = this.init.bind(this);
    this.isTestNet = isTestNet;
    this.init();
  }

  async init() {
    const { rpcEndpoint, apiEndpoint, code, scope, accountType, isTestNet } =
      this;

    const callApi = initAPI(apiEndpoint, isTestNet);
    const rpc = initRPC(rpcEndpoint);

    console.log("CODE AND SCOPE", code, scope);

    const hyphaContract = new HyphaSaleContract(rpc, code, scope);
    const tokenSaleAPI = new TokenSaleAPI(callApi, isTestNet);
    const roundDetails = await hyphaContract.getRoundDetails();
    const { roundNo, usdPerHypha, hyphaRemainingThisRound } = roundDetails;

    this.rpc = rpc;
    this.hyphaContract = hyphaContract;
    this.tokenSaleAPI = tokenSaleAPI;
    this.roundNo = roundNo;
    this.usdPerHypha = usdPerHypha;
    this.hyphaRemainingThisRound = hyphaRemainingThisRound;

    this.setAccountType(accountType);

    return roundDetails;
  }

  async selectPaymentMethod(paymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  setAccountType = (accountType) => {
    const AccountContractClass = getAccountContractClass(accountType);

    if (!AccountContractClass)
      throw new Error(`Unsupported account type ${accountType}`);

    const accountContract = new AccountContractClass(this.rpc);
    this.accountContract = accountContract;
    this.accountType = accountType;
  };

  getAccountDetails = (accountName) =>
    this.accountContract.getProfile(accountName);

  async convertHyphaToEOS(hypha) {
    let usdPerHypha = this.usdPerHypha;
    if (!usdPerHypha) {
      const roundDetails = await this.init();
      usdPerHypha = roundDetails.usdPerHypha;
    }

    const usd = Math.round(this.usdPerHypha * hypha * 100) / 100;
    const eos = await this.tokenSaleAPI.usdToEos(usd);
    return Number(eos).toFixed(EOS_DECIMAL_PLACES);
  }

  async convertHyphaToBTC(hypha) {
    let usdPerHypha = this.usdPerHypha;
    if (!usdPerHypha) {
      const roundDetails = await this.init();
      usdPerHypha = roundDetails.usdPerHypha;
    }

    const usd = Math.round(this.usdPerHypha * hypha * 100) / 100;
    const btc = await this.tokenSaleAPI.usdToBtc(usd);
    return Number(btc).toFixed(BTC_DECIMAL_PLACES);
  }

  async convertHyphaToUSD(hypha) {
    let usdPerHypha = this.usdPerHypha;
    if (usdPerHypha) await this.init();
    const usd = Math.round(this.usdPerHypha * hypha * 100) / 100;
    return usd;
  }

  async getBitcoinAddress(accountName, isTestNet) {
    const { bitcoinAddress } = await this.tokenSaleAPI.findMemberAddress(
      accountName
    );
    return bitcoinAddress;
  }
}

export default HyphaTokenSale;
