import { h } from "preact";
import Spinner from "../spinner";

const StepActions = ({ next, previous, loading }) => (
  <div class="steps-action">
    {previous && (
      <button id="button-previous" class="button secondary" onClick={previous}>
        Back
      </button>
    )}
    {next && (
      <button id="button-next" class="button primary" onClick={next}>
        {loading && <Spinner />}
        Next
      </button>
    )}
  </div>
);

export default StepActions;
