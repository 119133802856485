import Profile from "../types/profile";
// import { PPP } from "@hypha-dao/ppp-client-api";

class HyphaAccountContract {
  constructor(rpc) {
    this.rpc = rpc;
    this.getProfile = this.getProfile.bind(this);
  }

  /**
   * getProfile - returns profile instance based on account name
   * @param  {string} accountName
   * @return {Profile}
   * @memberof HyphaAccountContract
   */
  async getProfile(accountName) {
    try {
      const account = await this.rpc.get_account(accountName);

      if (!account || accountName !== account.account_name)
        throw new Error("account-not-found");

      // await new PPP.configure("prod");
      // const profileApi = await new PPP.profileApi(accountName);

      // const profiles = await profileApi.getProfiles(accountName);

      // console.log("PROFILE: ", profiles);
      return new Profile({ accountName });
    } catch (error) {
      console.log("ERROR");
      console.log(error);
      throw new Error("account-not-found");
    }
  }
}

export default HyphaAccountContract;
